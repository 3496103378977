import componentRegistry from '../registry.js';
import './countdown.less';

/**
 * @example
 *   <time
 *     class="js-countdown"
 *     data-format="'Il reste 'dd'j 'hh'h 'mm'm 'ss's'"
 *     datatime="2022-11-06T22:59:00.0000000Z"
 *   >
 *     ...
 *   </time>;
 *   //example data-format : "'Il reste 'dd'j 'hh'h 'mm'm 'ss's'";
 *   //example data-format : "'<span class=\"test\">Il reste </span>'dd'j 'hh'h 'mm'm 'ss's'";
 */

export default class Countdown {
  constructor({ el }) {
    this.el = el;
    this.endDate = new Date(el.getAttribute('datetime'));
    this.initializeClock();
  }

  createCountdown(endDate) {
    this.el.innerHTML = this.el
      .getAttribute('data-format')
      .replace(/\\.|".+?"|'.+?'|%[dmhs]|[dmhs]+/g, function (match) {
        switch (match.charAt(0)) {
          // Escaped char
          case '\\':
            return match.charAt(1);
          // Literal string
          case '"':
          case "'":
            return match.substr(1, match.length - 2);
          // Single Custom Format Specifiers.
          case '%':
            match = match.substr(1);
            break;
          // fix length of custom format specifiers:
          case 'h':
            match = match.substring(0, 2);
            break;
          case 'd':
          case 'm':
            match = match.substring(0, 4);
            break;
        }

        const t = endDate - new Date();
        switch (match) {
          case 'dd':
            return ('' + Math.floor(t / (1000 * 60 * 60 * 24))).substr(-2);
          case 'hh':
            return ('0' + Math.floor((t / (1000 * 60 * 60)) % 24)).substr(-2);
          case 'mm':
            return ('0' + Math.floor((t / 1000 / 60) % 60)).substr(-2);
          case 'ss':
            return ('0' + Math.floor((t / 1000) % 60)).substr(-2);
        }
        return '';
      });
  }

  initializeClock = () => {
    var endDate = this.endDate;
    const timeinterval = setInterval(() => {
      this.createCountdown(endDate);
      if (endDate <= new Date()) {
        clearInterval(timeinterval);
      }
    }, 1000);
  };
}

componentRegistry.define('js-countdown', Countdown);
