import componentRegistry from '../registry.js';
import MagnificPopup from '../../scripts/common/dynamic-imports/MagnificPopup.js';
import { isMobile } from '../../configuration/Configuration.js';
import './image-popin.less';

export default class ImagePopin extends MagnificPopup {
  constructor({ el }) {
    super(el, {
      items: {
        src: `
          <div class="ff-modal f-imagePopin ${el.dataset.cssClass || ''}">
            <img src="${el.href || el.dataset.href}" alt="${el.dataset.imageAlt}">
          </div>
        `,
      },
      midClick: true,
      mainClass: `mfp--modal${isMobile ? ' mfp--mobile' : ''}`,
      fixedContentPos: true,
    });
  }
}

componentRegistry.define('js-PopinImage', ImagePopin);
